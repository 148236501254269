// For large screen
@include media-breakpoint-up(xl) {
    .mt-lg-high{
        margin-top: 4.5vw;
    }
    .mt-lg-5rem{
        margin-top: 5rem;
    }
    .w-lg-45{
        width: 46%;
    }
    .w-lg-55{
        width: 54%;
    }

    .esim-div{
        #useESIM1{
            .phone-img-1{
                margin-left: 4vw;
            }
        }
    }
    .plan-container{
        max-height: 76vh;
    }
    .show-all-result-section{
        max-height: 50vh;
    }
}

// For large screen
@include media-breakpoint-between(xl, xxl) {
    .esim-div{
        #useESIM1{
            .phone-img-1{
                margin-left: -5.2vw;
            }
        }
    }



    .section-div {
        .section-header {
            .heading {
                font-size: 5.5vw;
            }

            .sub-heading {
                font-size: 4.25vw;
            }

            .helping-text {
                font-size: 1.75vw;
            }

            .info-heading{
                font-size: 1.75vw;
            }
            .supporting-text {
                font-size: 1.25vw;
            }
        }

        &.popular-destination-section{
            .section-header {
                .heading {
                    font-size: 4.25vw;
                }
                .helping-text{
                    font-size: 1.75vw;
                }
            }
            &:not(.user-custom-spacing){
                .section-header{
                    margin: 80px 0;
                }
            }
            &.user-custom-spacing{
                .section-header{
                    margin: 20px 0;
                }
            }
            .country-details{
                border-color: rgba(255, 255, 255, 0.85) !important;
            }
        }
    }

}
